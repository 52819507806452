/*!
 * jQuery Expander plugin
 * Author: Globalia
 * Contributor: Vivian Verswyvel
 * Licensed under the WTFPL license
 */

;(function ($, win, doc) {
    "use strict";

    var pluginName = 'expander';

    $.Expander = function(element, options, key) {
        var plugin = this;
        var defaults = {
            trigger: '.expander__trigger',
            target: '.expander__content'
        };

        plugin.item = this;
        plugin.element = element;
        plugin.$element = $(element);
        plugin.defaults = defaults;
        plugin.options = options;
        plugin.metadata = plugin.$element.data(pluginName);
        plugin.name = key;

        plugin.init = function() {
            plugin.config = $.extend({}, plugin.defaults, plugin.options, plugin.metadata);
            plugin.$items = plugin.$element;
            plugin.$targets =  plugin.$element.find(plugin.config.target);
            plugin.$triggers = plugin.$element.find(plugin.config.trigger);

            plugin.$paddings = parseInt(plugin.$element.css('padding-bottom'), 10); // get padding bottom to be able to increment on expand or reset default value on collapse
            plugin.expander();

            return plugin;
        };

        plugin.expand = function(elem) {
            var instance = this;

            if (typeof elem === 'undefined') {
                elem = instance.$items; // default is current instance
            }

            var wrapper = elem.find('.expander__wrapper'),
                contentH = wrapper.outerHeight(true); // get real height of hidden content

            elem.find(plugin.config.target).css('height', contentH); // set height on hidden content

            elem
                .addClass('js-expanded')
                .css('padding-bottom', instance.$paddings + contentH); // add target height to padding bottom of element
        };

        plugin.collapse = function(elem) {
            var instance = this;

            if (typeof elem === 'undefined') {
                elem = instance.$items;
            }

            elem.find(plugin.config.target).css('height', 0);

            elem
                .removeClass('js-expanded')
                .css('padding-bottom', instance.$paddings);
        };

        plugin.expander = function() {
            var instance = this;

            if (instance.$items.find('.expander__wrapper').length == 0) {
                instance.$targets.wrapInner( "<div class='expander__wrapper'></div>" ); // we need an inner wrapper to be able to calculate the height before we expand
            }

            instance.$triggers.on('click', function(e) {
                e.preventDefault();

                if (instance.$items.hasClass('js-expanded')) {
                    plugin.collapse();
                } else {
                    plugin.collapse($('.js-expanded')); // first collapse already expanded elements
                    plugin.expand();
                }
            });
        };

        plugin.callFunction = function(call, options) {
            if(typeof plugin[call] === 'function') {
                return plugin[call](options);
            }
        };

        plugin.init();
    };

    $.fn[pluginName] = function(call, options) {
        return this.each(function() {
            if (undefined === $(this).data('Expander')) {
                if(typeof options !== 'undefined' && typeof options !== 'object')
                    return;
                var plugin = new $.Expander(this, options);
                $(this).data('Expander',plugin);
            } else {
                return $(this).data('Expander').callFunction(call,options);
            }
        });
    };

    $(doc).on('ready domloaded ajaxloaded', function () {
        $(doc).find('[data-expander]').expander();
    });

    // Collapse expanded areas on resize for desktops / on orientation change for mobiles

    var isTouch = ('ontouchstart' in window) || (window.navigator.msMaxTouchPoints),
        collapseEvent = '';

    if (isTouch) {
        collapseEvent = 'orientationchange';
    } else {
        collapseEvent = 'resize';
    }

    $(win).on(collapseEvent, function () {
        $(doc).find('[data-expander]').expander('collapse', $('.js-expanded'));
    });

}(jQuery, window, document));
