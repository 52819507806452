(function($) {
    "use strict"; // Start of use strict

    $('.js-toggle-nav').on('click', function(event) {
        event.preventDefault();
        $('.site-wrapper').toggleClass('show-nav');
        $('.nav__icon').toggleClass('active');
    });

    $(document).keyup(function(e) {
    if (e.keyCode == 27) {
        if ($('.site-wrapper').hasClass('show-nav')) {
            $('.site-wrapper').removeClass('show-nav');
            $('.nav__icon').removeClass('active');
        }
    }
});


})(jQuery); // End of use strict
