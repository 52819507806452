var HtmlUtil = {
    getResponseContainer: function(html) {
        html = html.replace(/\<body.*\>/, '<div class="body">')
                   .replace('</body>', '</div>')
                   .replace('<head>', '<div class="head">')
                   .replace('</head>', '</div>');

        return $('<div />').html(html);
    }
};

function is_touch_device() {
 return (('ontouchstart' in window)
      || (navigator.MaxTouchPoints > 0)
      || (navigator.msMaxTouchPoints > 0));
}


(function($) {
    $(document).ready(function() {

        // Init Foundation Equalizer
        /*$(document).foundation({
            equalizer : {
                // Specify if Equalizer should make elements equal height once they become stacked.
                equalize_on_stack: true
            }
        });*/

        if (is_touch_device()) {
            $('.site-menu').addClass('is-touch');
        } else {
            $('.site-menu').find('span').on('click', function() {
                $(this).next('ul').slideToggle();
            });
        }

        $('[data-js-remove]').remove();

        $('.expander__close').on('click', function() {
            $(this).parents('[data-expander]').expander('collapse');
        });

        $('.anchor').each(function() {
            var $this = $(this);

            if ( $this.attr('href') !== "" ) {
                var element = $this.attr('href');

                $this.on('click', function(e) {
                    e.preventDefault();

                    $('.anchor').removeClass('is-active');
                    $this.addClass('is-active');

                    $('html,body')
                        .animate({
                          scrollTop: $(element).offset().top
                        },'500');
                });
            }
        });

        $('.archives').find('.js-submit').click(function(){
            var $annee = $('.archives').find('.js-select-annee');
            var url = $annee.val();
            
            if (url != 'hide') {
                window.location.href = url;
            }

            return false;
        });

        $('#main__container').on('click', '[data-js-albums] a', function(ev) {

            ev.preventDefault();

            if ($('#magnificpopup__container__ajax').size() === 0) {
                $('<div />')
                    .attr('id', 'magnific__popup__container__ajax')
                    .css('display', 'none')
                    .appendTo('body');
            }

            $.ajax($(this).attr('href'), {
                success: function(data) {
                    var dataContainer = HtmlUtil.getResponseContainer(data);
                    var gallery = dataContainer.find('#main__container [data-js-popupgallery]');

                    $('#magnificpopup__container__ajax').html(gallery);

                    $.bindDefaultMagnificPopupTo(gallery);
                    gallery.find('a').first().trigger('click');
                }
            });

            return false;
        });
        
        if ($(window.location.hash).selector) {
            var selector = $(window.location.hash).selector;
            var $target = $('.'+selector.slice(1)).first();
            if ($target.length) {
                $('html,body').animate({
                    scrollTop: $target.offset().top
                }, 1000);
            }
        }

    });

})(jQuery);
