(function($) {

    $.bindDefaultMagnificPopupTo = function(jqElement) {

        var config = {
            delegate: 'a',
            type: 'image',
            mainClass: 'adisq-mfb-custom',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                titleSrc: function(item) {
                    return item.el.attr('title'); //+ '<small>by Marsel Van Oosten</small>'
                }
            }
        }

        if (jqElement.data('js-popupgallery-closelabel')) {
            config.tClose = jqElement.data('js-popupgallery-closelabel');
        }

        if (jqElement.data('js-popupgallery-previouslabel')) {
            config.gallery.tPrev = jqElement.data('js-popupgallery-previouslabel');
        }

        if (jqElement.data('js-popupgallery-nextlabel')) {
            config.gallery.tNext = jqElement.data('js-popupgallery-nextlabel');
        }

        if (jqElement.data('js-popupgallery-counterlabel')) {
            config.gallery.tCounter = jqElement.data('js-popupgallery-counterlabel');
        }

        jqElement.magnificPopup(config);
    }

    $(document).ready(function() {
        $.bindDefaultMagnificPopupTo($('[data-js-popupgallery]'));
    });

})(jQuery);
