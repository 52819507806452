/*====================================
=            ON DOM READY            =
====================================*/

// Need a refactor
$(function() {
    $('.js-accordionTrigger').click(function( event ) {
        event.preventDefault();
        var $trigger = $(this).parent();
        var $item = $trigger.next();
        $trigger.toggleClass('is-expanded');
        $item.toggleClass('is-collapsed is-expanded animateIn');
    });
});
