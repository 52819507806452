$(document).ready(function() {
    var $element = $('.js-scroll-top');
    var scrollDuration = 300; // Duration of scrolling to top
	// Smooth animation when scrolling
	$element.click(function(event) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, scrollDuration);
    });
});
