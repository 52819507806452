var OptionalFields = (function () {
    'use strict';

    var inputs = null,
        container = null; 

    function _getElements() {
        inputs = document.querySelectorAll('.js-optional-group input');
    }

    function _bindListeners() {
        Array.prototype.forEach.call(inputs, function(el) {
            el.addEventListener('change', _inputsHandler);
        });
    }
    
    function _inputsHandler() {
        container = getClosest(this, '.js-optional-field-container');
        
        if (this.getAttribute('type') === 'checkbox') {
            if (hasClass(this, 'js-optional-toggler') && this.checked) {
                addClass(container, 'optional-is-visible');
            } else if (hasClass(this, 'js-optional-toggler') && !this.checked) {
                removeClass(container, 'optional-is-visible');
            }
        }
        
        if (this.getAttribute('type') === 'radio') {
            if (hasClass(this, 'js-optional-toggler') && this.checked) {
                addClass(container, 'optional-is-visible');
            } else {
                removeClass(container, 'optional-is-visible');
            }
        }
    }

    function init() {
        _getElements();
        _bindListeners();
    }

    var public_API = {
        init: init
    };

    return public_API;

})();

OptionalFields.init();
