/*
* Tranform select menu into ul
*/

$('.js-custom-select').each(function(){
    var $this = $(this), numberOfOptions = $(this).children('option').length;
    var $toggle = $this.siblings('.js-select-toggle');

    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');

    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option:selected').eq(0).text());

    var $list = $('<ul />', {
        'class': 'select-options'
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }

    var $listItems = $list.children('li');

/*$styledSelect.click(function(e) {

e.stopPropagation();
$('div.select-styled.active').each(function(){
$(this).removeClass('active').next('ul.select-options').hide();
});
$(this).toggleClass('active').next('ul.select-options').toggle();
$styledSelect.toggleClass('active');
});*/

    $toggle.click(function(e) {
        e.stopPropagation();
        //console.log('thugler')
        $('div.select-styled.active').each(function(){
            $(this).removeClass('active').next('ul.select-options').hide();
        });
        $styledSelect.toggleClass('active').next('ul.select-options').toggle();
        $styledSelect.toggleClass('active');
        $toggle.toggleClass('active');
    });



    $listItems.click(function(e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.hide();
        $this.eq($(this).index()).prop('selected',true);
        $this.trigger('change');
    //console.log($this.val());
    });

    $(document).click(function() {
        $styledSelect.removeClass('active');
        $list.hide();
    });

});
