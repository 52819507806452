(function($) {
    "use strict"; // Start of use strict

    //$ = jQuery;
    $.fn.select2.defaults.set('language', 'fr');
    $(".js-example-basic-multiple").select2({
        // placeholder: "Sélectionner les artistes participants",
    });

    $('.sticky-promo .btn-close').on('click', function() {
        $(this).parents('.sticky-promo').addClass('is-hidden');
    });


})(jQuery); // End of use strict
