(function($) {
    "use strict";

    function slickHeight() {
        if ( window.outerWidth < 768 ) {
            var $slickElement = $('[js-slider="banner"]'),
                maxHeight = 0;

            $slickElement.find('section').each(function() {
                maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
            });

            $slickElement.find('.slick-track').height(maxHeight);
        }
    }

    var slickSlider = function() {
        var $slickElement = $('[js-slider="banner"]');

        $slickElement.length && $slickElement.slick({
            dots: true,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right"></i></button>'
        });

        slickHeight();

        $(window).resize(function() {
            slickHeight();
        });
    }();

})(jQuery);
